import { defineAsyncComponent } from 'vue'
const editCom = defineAsyncComponent(() => import('./editExhibitionManage.vue'))
const editComMeet = defineAsyncComponent(() =>
  import('./editMeetingManage.vue')
)
const previewCom = defineAsyncComponent(() =>
  import('./previewEXhibitionManage.vue')
)
const editTickets = defineAsyncComponent(() =>
  import('./editTicketsManage.vue')
)
const refundAudit = defineAsyncComponent(() => import('./refundAudit.vue'))
const ticketsThrowDialog = defineAsyncComponent(() =>
  import('./ticketsThrowDialog.vue')
)
const editTicketsThrow = defineAsyncComponent(() =>
  import('./editTicketsThrow.vue')
)
const throwResult = defineAsyncComponent(() => import('./throwResult.vue'))
const importThrow = defineAsyncComponent(() => import('./importThrow.vue'))
const editCouponCode = defineAsyncComponent(() =>
  import('./editCouponCode.vue')
)
const changeCouponStatus = defineAsyncComponent(() =>
  import('./changeCouponStatus.vue')
)
const ticketsSharePopup = defineAsyncComponent(() =>
  import('./ticketsSharePopup.vue')
)
const editConfigDrawer = defineAsyncComponent(() =>
  import('./editConfigDrawer.vue')
)
const editConfigDrawer1 = defineAsyncComponent(() =>
  import('./editConfigDrawer1.vue')
)
const editTicketsGatherConfigPopup = defineAsyncComponent(() =>
  import('./editTicketsGatherConfigPopup.vue')
)

const channelAddDrawer = defineAsyncComponent(() =>
  import('./channelAddDrawer.vue')
)
const mobileReport = defineAsyncComponent(() => import('./mobileReport.vue'))

export const useComponent = () => {
  return {
    editCom,
    editComMeet,
    previewCom,
    editTickets,
    refundAudit,
    ticketsThrowDialog,
    editTicketsThrow,
    importThrow,
    throwResult,
    editCouponCode,
    changeCouponStatus,
    ticketsSharePopup,
    editConfigDrawer,
    editConfigDrawer1,
    editTicketsGatherConfigPopup,
    channelAddDrawer,
    mobileReport,
  }
}
