/**
 * 使用场景: 鼠标不断点击触发，输入框中不断输入(单位时间内只触发一次)。
 * @description: 防抖函数
 * @param {Function} fn
 * @param {Number} wait
 * @return fn 函数
 */

export function debounce(fn, delay = 600) {
  let timer = null
  return function () {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}
